<template>
  <div class="container">
    <div class="promotionBox">
      <div class="paddingPub pro_title">
        <div class="head">{{ $t('cond.title') }}</div>
        <div class="desc">{{ $t('cond.desc') }}</div>
      </div>
      <div class="paddingPub contentBox">
        <div class="content">
          <ol>
            <li>
              {{ $t('cond.l1') }}
              <ol type="a">
                <li>{{ $t('cond.l1_1') }}</li>
                <li>{{ $t('cond.l1_2') }}</li>
                <li>{{ $t('cond.l1_3') }}</li>
                <li>{{ $t('cond.l1_4') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l2') }}
              <ol type="a">
                <li>{{ $t('cond.l2_1') }}</li>
                <li>{{ $t('cond.l2_2') }}</li>
                <li>{{ $t('cond.l2_3') }}</li>
                <li>{{ $t('cond.l2_4') }}</li>
                <li>{{ $t('cond.l2_5') }}</li>
                <li>{{ $t('cond.l2_6') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l3') }}
              <ol type="a">
                <li>{{ $t('cond.l3_1') }}</li>
                <li>{{ $t('cond.l3_2') }}</li>
                <li>{{ $t('cond.l3_3') }}</li>
                <li>{{ $t('cond.l3_4') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l4') }}
              <ol type="a">
                <li>{{ $t('cond.l4_1') }}</li>
                <li>{{ $t('cond.l4_2') }}</li>
                <li>{{ $t('cond.l4_3') }}</li>
                <li>{{ $t('cond.l4_4') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l5') }}
              <ol type="a">
                <li>{{ $t('cond.l5_1') }}</li>
                <li>{{ $t('cond.l5_2') }}</li>
                <li>{{ $t('cond.l5_3') }}</li>
                <li>{{ $t('cond.l5_4') }}</li>
                <li>{{ $t('cond.l5_5') }}</li>
                <li>{{ $t('cond.l5_6') }}</li>
                <li>{{ $t('cond.l5_7') }}</li>
                <li>{{ $t('cond.l5_8') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l6') }}
              <ol type="a">
                <li>{{ $t('cond.l6_1') }}</li>
                <li>{{ $t('cond.l6_2') }}</li>
                <li>{{ $t('cond.l6_3') }}</li>
                <li>{{ $t('cond.l6_4') }}</li>
                <li>{{ $t('cond.l6_5') }}</li>
                <li>{{ $t('cond.l6_6') }}</li>
                <li>{{ $t('cond.l6_7') }}</li>
                <li>{{ $t('cond.l6_8') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l7') }}
              <ol type="a">
                <li>{{ $t('cond.l7_1') }}</li>
                <li>{{ $t('cond.l7_2') }}</li>
                <li>{{ $t('cond.l7_3') }}</li>
                <li>{{ $t('cond.l7_4') }}</li>
                <li>{{ $t('cond.l7_5') }}</li>
                <li>{{ $t('cond.l7_6') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l8') }}
              <ol type="a">
                <li>{{ $t('cond.l8_1') }}</li>
                <li>{{ $t('cond.l8_2') }}</li>
                <li>{{ $t('cond.l8_3') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l9') }}
              <ol type="a">
                <li>{{ $t('cond.l9_1') }}</li>
                <li>{{ $t('cond.l9_2') }}</li>
                <li>{{ $t('cond.l9_3') }}</li>
                <li>{{ $t('cond.l9_4') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l10') }}
              <ol type="a">
                <li>
                  {{ $t('cond.l10_1') }}
                  <ol type="i">
                    <li>{{ $t('cond.l10_1_1') }}</li>
                    <li>{{ $t('cond.l10_1_2') }}</li>
                    <li>{{ $t('cond.l10_1_3') }}</li>
                    <li>{{ $t('cond.l10_1_4') }}</li>
                  </ol>
                </li>
                <li>{{ $t('cond.l10_2') }}</li>
                <li>{{ $t('cond.l10_3') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l11') }}
              <ol type="a">
                <li>
                  {{ $t('cond.l11_1') }}
                  <ol type="i">
                    <li>{{ $t('cond.l11_1_1') }}</li>
                    <li>{{ $t('cond.l11_1_2') }}</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l12') }}
              <ol type="a">
                <li>{{ $t('cond.l12_1') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l13') }}
              <ol type="a">
                <li>{{ $t('cond.l3_1') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l14') }}
              <ol type="a">
                <li>{{ $t('cond.l14_1') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l15') }}
              <ol type="a">
                <li>{{ $t('cond.l15_1') }}</li>
                <li>{{ $t('cond.l15_2') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l16') }}
              <ol type="a">
                <li>{{ $t('cond.l15_1') }}</li>
                <li>{{ $t('cond.l15_2') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('cond.l17') }}
              <ol type="a">
                <li>
                  {{ $t('cond.l17_1') }}
                  <ol type="i">
                    <li>{{ $t('cond.l17_1_1') }}</li>
                    <li>{{ $t('cond.l17_1_2') }}</li>
                    <li>{{ $t('cond.l17_1_3') }}</li>
                    <li>{{ $t('cond.l17_1_4') }}</li>
                    <li>{{ $t('cond.l17_1_5') }}</li>
                  </ol>
                </li>
                <li>{{ $t('cond.l17_2') }}</li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Conditions',
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.promotionBox {
  position: relative;
  padding-bottom: 34px;
  &::after{
    width: 66%;
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    content: '';
    border-top: 2px dashed #000B8C;
  }
  .pro_title{
    color: #ffffff;
    line-height: 58px;
    padding-top: 4%;
    padding-bottom: 4%;
    background-color: #091490;
    background-image: url("../assets/images/policy_bg.png");
    background-position: right -4%;
    background-repeat: no-repeat;
    background-size: 35% 130%;
    .head{
      font-size: 32px;
      font-weight: bold;
    }
    .desc{
      font-size: 16px;
    }
  }
}
.contentBox{
  padding-top: 4%;
  padding-bottom: 8%;
  color: #303030;
  .title{
    font-size: 24px;
    color: #000000;
    font-weight: bold;
    margin-bottom: 4%;
  }
  .content {
    .headTop{
      margin-bottom: 16px;
    }
    strong{
      display: inline-block;
      margin-bottom: 8px;
      font-size: 15px;
    }
    ol {
      color: #000000;
      line-height: 28px;
    }
  }
}
@media screen and (max-width: 576px) {
  .promotionBox{
    &::after{
      width: 0;
    }
  }
}
</style>
